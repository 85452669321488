import React from "react";

const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary py-3" data-bs-theme="dark" id="home">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="83" className="navbar-logo position-absolute top-50 start-50 translate-middle" role="img"></svg>
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-evenly" id="navbarToggler">
                    <ul className="navbar-nav 1">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#about">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#services">Services</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav 2">
                        <li className="nav-item">
                            <a className="nav-link" href="#contact">Contact Us</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#hours" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Hours
                            </a>
                            <ul class="dropdown-menu">
                                <li><p className="lead text-center"><strong>Mon-Fri: </strong>8:30am-5:30pm</p></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default NavBar