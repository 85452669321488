import React from "react";

const Services = () => {
    return (
        <div className="container-fluid bg-dark-subtle py-5" id="services">
            <h2 className="display-6 text-center py-3">Services</h2>
            <div className="container-lg text-center">
                At Rocky Mountain Repair, We service construction equipment, domestic passenger cars, diesel and gas pickups. See our rates below.
            </div>
            <div className="container text-center">
                <h2 className="display-6 text-center py-4"> Automotive</h2>
                <div className="row row-cols-2">
                    <h2 className="col">Automotive Repair</h2>
                    <h2 className="col">Price</h2>
                    <div className="col">diagnosis, major/minor repairs, suspension, power train, brakes, fuel system</div>
                    <div className="col">$105/hr</div>
                </div>
            </div>
            <div className="container text-center">
                <h2 className="display-6 text-center py-4">Diesel</h2>
                <div className="row row-cols-2">
                    <h2 className="col">Diesel Repair</h2>
                    <h2 className="col">Price</h2>
                    <div className="col">diagnosis, major/minor repairs, suspension, power train, brakes, fuel system</div>
                    <div className="col">$120/hr</div>
                </div>
            </div>
            <div className="container text-center">
                <h2 className="display-6 text-center py-4">Equipment</h2>
                <div className="row row-cols-2">
                    <h2 className="col">Heavy Equipment Repair</h2>
                    <h2 className="col">Price</h2>
                    <div className="col">diagnosis, major/minor repairs, hydraulics, we service major makes/models, medium sized contruction equipment, skid steer, fork lift, excavators, and scissor lifts</div>
                    <div className="col">$135/hr</div>
                </div>
            </div>
        </div>
    )
};

export default Services;