import React from "react"

const Logo = () => {
    return (
        <div className="container text-center mx-auto py-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" className="logo mx-50 p-2" role="img"></svg>
        </div>
    )
}

export default Logo;