import React from "react";

const Hero = () => {
    return (
        <div className="container col-xxl-8 px-4 py-5">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                <div className="col-lg-6 text-start pb-5" id="about">
                    <h1 className="display-5 fw-bold lh-1 mb-3">About Rocky Mountain Repair</h1>
                    <p className="lead text-start">We are a multi-service repair shop, serving the Pueblo West community. We take pride in helping you meet your repair needs whether it be construction equipment, domestic passenger cars, or diesel and gas pickups. The foundation of our business is built with diverse knowledge, integrity, and a friendly personal interaction.</p>
                </div>
                <div className="col-lg-6 pb-5" id="contact-container">
                    <h1 className="fs-3 lh-1 mb-3 text-center">Phone</h1>
                    <p className="lead text-center"><a href="tel:+1719-691-9799" className="text-decoration-none">(719)691-9799</a></p>
                    <h1 className="fs-3 lh-1 mb-3 text-center">Address</h1>
                    <p className="lead text-center">92 N Silicon Drive, Pueblo West, CO 81007</p>
                    <h1 className="fs-3 lh-1 mb-3 text-center">email</h1>
                    <p className="lead text-center">rmrepairltd@gmail.com</p>
                    <h1 className="fs-3 lh-1 mb-3 text-center">Hours</h1>
                    <p className="lead text-center"><strong>Mon-Fri:</strong>8:30am-5:30pm</p>
                </div>
            </div>
        </div>
    )
};

export default Hero;
