import React from 'react';
import './App.css';
import NavBar from './Components/NavBar/NavBar.js';
import Hero from './Components/Hero/Hero.js'
import Services from './Components/Services/Services.js';
import Logo from './Components/Logo/Logo.js';
import Carousel from './Components/Carousel/Carousel.js';
import ContactUs from './Components/ContactUs/ContactUs';
import Footer from './Components/Footer/Footer.js';
function App() {
  return (
    <div className="App">
      <NavBar />
      <Hero />
      <Services />
      <Logo />
      <Carousel />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
