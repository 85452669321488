import React from "react";

const Footer = () => {
    return (
        <div class="container">
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <p class="col-md-4 mb-0 text-body-secondary">© 2023 Rocky Mountain Repair, Inc</p>

                <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                    <svg class="bi me-2 logo" width="60" height="52"></svg>
                </a>

                <ul class="nav col-md-4 justify-content-end">
                    <li class="nav-item"><a href="#home" class="nav-link px-2 text-body-secondary">Home</a></li>
                    <li class="nav-item"><a href="#about" class="nav-link px-2 text-body-secondary">About</a></li>
                    <li class="nav-item"><a href="#services" class="nav-link px-2 text-body-secondary">Services</a></li>
                    <li class="nav-item"><a href="#contact" class="nav-link px-2 text-body-secondary">Contact</a></li>
                    <a href="https://www.facebook.com/people/Rocky-Mountain-Repair-Ltd/100091317999416/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                    </a>
                </ul>
            </footer>
        </div >
    )
};

export default Footer;