import React from "react";

const ContactUs = () => {
    return (
        <div className="container text-center" id="contact">
            <h2 className="display-6 text-center py-4">Contact Us</h2>
            <div className="container-lg text-center pb-5">
                Call (719)691-9799 to schedule an appointment.
            </div>
            <div className="pb-5" id="contact-container">
                <h1 className="fs-3 lh-1 mb-3 text-center">Phone</h1>
                <p className="lead text-center"><a href="tel:+1719-691-9799" className="text-decoration-none">(719)691-9799</a></p>
                <h1 className="fs-3 lh-1 mb-3 text-center">Address</h1>
                <p className="lead text-center">92 N Silicon Drive, Pueblo West, CO 81007</p>
                <h1 className="fs-3 lh-1 mb-3 text-center">email</h1>
                <p className="lead text-center">rmrepairltd@gmail.com</p>
                <h1 className="fs-3 lh-1 mb-3 text-center">Facebook</h1>
                <p className="lead text-center">Connect with us on Facebook!</p>
                <a href="https://www.facebook.com/people/Rocky-Mountain-Repair-Ltd/100091317999416/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                </a>
            </div>
        </div>
    )
};

export default ContactUs;